import React, { useState } from 'react'
import styled from 'styled-components'
import { color, above } from 'ui/src/styles/index'
import GlobalStyles from 'ui/src/styles/global'
import LoadAdobe from '../components/LoadAdobe/LoadAdobe'
import Header from '../components/Header/header'
import SecondaryNav from '../components/SecondaryNav/secondaryNav'
import Footer from '../components/Footer/footer'
import ConsentBannerWrapper from '../components/ConsentBanner/ConsentBannerWrapper'

function Layout({
  hero,
  secondaryNavigation = false,
  location,
  noBackground = false,
  isStoreLocator = false,
  children,
  locale = undefined,
  alternativeLogo,
}) {
  const [allowTracking, setAllowTracking] = useState(false)

  const isProd = process.env.GATSBY_DEPLOY_ENVIRONMENT === 'production'
  const loadOneTrust =
    (process.env.GATSBY_ONETRUST_PROD === 'true' && isProd) ||
    (process.env.GATSBY_ONETRUST_DEV === 'true' && !isProd)
  const trackingAllowed = loadOneTrust || allowTracking

  // if onetrust is enabled dont check the state, if its disabled check if allowTracking is true
  return (
    <>
      {trackingAllowed && (
        <>
          <LoadAdobe oneTrustEnabled={loadOneTrust} />
        </>
      )}

      <GlobalStyles noBackground={noBackground} bgColor={color.white} />
      {!loadOneTrust && (
        <ConsentBannerWrapper setAllowTracking={setAllowTracking} locale={locale} />
      )}
      <Header hero={hero} alternativeLogo={alternativeLogo} location={location} locale={locale} />
      {secondaryNavigation && secondaryNavigation.link && (
        <SecondaryNav links={secondaryNavigation.link} location={location} locale={locale} />
      )}
      <Main location={location} style={{ minHeight: '400px' }} isStoreLocator={isStoreLocator}>
        {children}
      </Main>
      {!isStoreLocator && <Footer locale={locale} />}
    </>
  )
}

const Main = styled.main`
  margin: ${p => (p.isStoreLocator ? '0px' : '50px auto')};
  max-width: ${p => (p.isStoreLocator ? '' : '1392px')};

  ${above.tablet`
    margin: ${p => (p.isStoreLocator ? '0px' : '92px auto')};
  `}
`

export default Layout
